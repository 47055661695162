export default [
  'World War II',
  'Science',
  'Christianity',
  'Philosophy',
  'Technology',
  'Art',
  'History',
  'Psychology',
  'Mathematics',
  'Literature',
  'Music',
  'Politics',
  'Economics',
  'Education',
  'Environment',
  'Medicine',
  'Sociology',
  'Physics',
  'Astronomy',
  'Chemistry',
  'Biology',
  'Anthropology',
  'Architecture',
  'Engineering',
  'Law',
  'Ethics',
  'Sports',
  'Globalization',
  'Healthcare',
  'Culture',
  'Linguistics',
  'Fashion',
  'Business',
  'Journalism',
  'Feminism',
  'Culinary Arts',
  'Geography',
  'Cinema',
  'Photography',
  'Robotics',
  'AI (Artificial Intelligence)',
  'Cybersecurity',
  'Blockchain',
  'Renewable Energy',
  'Marketing',
  'AI Ethics',
  'Virtual Reality'
]