export default [
  'berrylicious',
  'butterscotch',
  'caramel',
  'cheesy',
  'chocolate',
  'salted',
  'egg',
  'salty',
  'smoked',
  'strawberry',
  'sugar',
  'sweet',
  'zesty'
];