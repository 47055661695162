export default [
  'akok',
  'apple',
  'crumble',
  'brownies',
  'buchty',
  'cake',
  'cheesecake',
  'cekodok',
  'creme',
  'fritters',
  'cendol',
  'chocolate',
  'cupcake',
  'eclair',
  'frgal',
  'krapfen',
  'kremrole',
  'muffin',
  'macaroon',
  'tart',
  'vetrnik',
  'waffle'
];